import { useEffect, useRef, RefObject } from "react"
import type { ComponentType } from "react"

export function triggerClick(ref: RefObject<HTMLElement>) {
    const buttonElement = ref.current?.querySelector("button")
    const linkElement = ref.current?.querySelector("a")

    if (buttonElement) {
        buttonElement.click()
    } else if (linkElement) {
        linkElement.click()
    } else {
        console.error(`Error: Neither button nor link found for the given ref.`)
    }
}

export function hasCommandShortcut(name: string | undefined): boolean {
    return name ? name.includes("(cmd)") : false
}

export function hasShiftShortcut(name: string | undefined): boolean {
    return name ? name.includes("(shift)") : false
}

export function extractShortcutKey(name: string | undefined): string | null {
    const shortcutKey = name ? name.split("[")[1]?.split("]")[0] : null
    if (!shortcutKey) {
        console.error(`Error: Missing key in shortcut layer (${name}).`)
    }
    return shortcutKey
}

type ShortCutOptions = {
    shortcutKey: string
    hasCmd?: boolean
    hasShift?: boolean
}

// Função auxiliar para armazenar a tecla pressionada.
function storeKeyPressed(event) {
    const keysPressed = JSON.parse(sessionStorage.getItem("typedKey") || "[]")
    keysPressed.push(event.key)
    sessionStorage.setItem("typedKey", JSON.stringify(keysPressed.slice(-1)))
}

// Função auxiliar para verificar a última tecla pressionada.
function isShortcutKeyPressed(keysPressed, shortcutKey) {
    return (
        keysPressed[keysPressed.length - 1].toLowerCase() ===
        shortcutKey.toLowerCase()
    )
}

function shouldStopExecution(event) {
    return event.shiftKey || event.ctrlKey || event.altKey || event.metaKey
}

export const useShortcutKey = (options: ShortCutOptions, callback) => {
    const { shortcutKey, hasCmd = false, hasShift = false } = options
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.target instanceof HTMLInputElement) return

            if (!(hasCmd || hasShift) && shouldStopExecution(event)) {
                return
            }

            storeKeyPressed(event)

            const keysPressed = JSON.parse(
                sessionStorage.getItem("typedKey") || "[]"
            )

            if (isShortcutKeyPressed(keysPressed, shortcutKey)) {
                event.preventDefault()
                callback()
            }
        }
        window.addEventListener("keydown", handleKeyDown)
        return () => {
            window.removeEventListener("keydown", handleKeyDown)
        }
    }, [shortcutKey, hasCmd, hasShift, callback])
}

export const useUnload = () => {
    useEffect(() => {
        const handleUnload = () => sessionStorage.removeItem("keysPressed")
        window.addEventListener("beforeunload", handleUnload)
        return () => {
            window.removeEventListener("beforeunload", handleUnload)
        }
    }, [])
}

export const useFocus = () => {
    useEffect(() => {
        window.focus()
    }, [])
}
